import React from "react"
import { graphql } from "gatsby"
import Slider from "react-slick"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Historia = ({data}) => {
  let mainColor = "#E19E8C";
  let pageName ="Nuestra Historia";
  const settings = {
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Layout Color={mainColor} Imagen={data.coverImage.childImageSharp.fluid} Titulo={pageName}>
      <SEO title={pageName} description="Conoce los detalles detras de esta historia de amor <3"/>
      <div className="Page__Text Historia__Text" dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}/>
      <div className="Page__Text Historia__Text">
        <Slider {...settings}>
          <div>
            <img srcSet={data.propuesta1.childImageSharp.fixed.srcSet}
              sizes={data.propuesta1.childImageSharp.fixed.sizes}
              src={data.propuesta1.childImageSharp.fixed.src} alt="RPropuesta 1" />
          </div>
          <div>
            <img srcSet={data.propuesta2.childImageSharp.fixed.srcSet}
              sizes={data.propuesta2.childImageSharp.fixed.sizes}
              src={data.propuesta2.childImageSharp.fixed.src} alt="RPropuesta 2" />
          </div>
          <div>
            <img srcSet={data.propuesta3.childImageSharp.fixed.srcSet}
              sizes={data.propuesta3.childImageSharp.fixed.sizes}
              src={data.propuesta3.childImageSharp.fixed.src} alt="RPropuesta 3" />
          </div>
          <div>
            <img srcSet={data.propuesta4.childImageSharp.fixed.srcSet}
              sizes={data.propuesta4.childImageSharp.fixed.sizes}
              src={data.propuesta4.childImageSharp.fixed.src} alt="RPropuesta 4" />
          </div>
          <div>
            <img srcSet={data.propuesta5.childImageSharp.fixed.srcSet}
              sizes={data.propuesta5.childImageSharp.fixed.sizes}
              src={data.propuesta5.childImageSharp.fixed.src} alt="RPropuesta 5" />
          </div>
        </Slider>
      </div>
      <div className="Page__Text Historia__Text">
        <video poster={data.portada.childImageSharp.fixed.src} controls>
          <source src="https://res.cloudinary.com/rafaremo/video/upload/v1558065199/Propuesta_Matrimonio.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Layout>
  )
}

export default Historia

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "beso-lu.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    videoImage: file(relativePath: { eq: "beso-casa.JPG" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    propuesta1: file(relativePath: { eq: "historia/propuesta1.JPG" }){
      childImageSharp {
        fixed(width: 400){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    propuesta2: file(relativePath: { eq: "historia/propuesta2.JPG" }){
      childImageSharp {
        fixed(width: 400){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    propuesta3: file(relativePath: { eq: "historia/propuesta3.JPG" }){
      childImageSharp {
        fixed(width: 400){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    propuesta4: file(relativePath: { eq: "historia/propuesta4.JPG" }){
      childImageSharp {
        fixed(width: 400){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    propuesta5: file(relativePath: { eq: "historia/propuesta5.JPG" }){
      childImageSharp {
        fixed(width: 400){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    
    portada: file(relativePath: { eq: "historia/portada.JPG" }){
      childImageSharp {
        fixed(width: 1920){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
        }
      }
    }
  }
`;